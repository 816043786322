import { Visibility, VisibilityOff } from "@mui/icons-material";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { Card, Grid, IconButton, InputAdornment, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { useFormik } from "formik";
import 'i18n';
import { t } from 'i18next';
import Head from "next/head";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import CartApi from "../../src/api/carts/CartApi";
import MeApi from "../../src/api/me/MeApi";
import { KeratinErrors, KeratinSingleton } from "../../src/auth/KeratinSingleton";
import { setUserLoggedIn } from "../../src/redux/session/session.actions";
import { SEDEO_PALETTE } from "../../src/theme/themeColors";
import { DefaultRoundButton } from "../../src/ui/common/DefaultRoundButton";
import { LoadingAnimation } from "../../src/ui/common/LoadingAnimation";
import { InputField } from "../../src/ui/common/input-field";
import AmplitudeHelper from "../../src/utils/AmplitudeHelper";
import BugsnagHelper from "../../src/utils/BugsnagHelper";
import * as fbq from "../../src/utils/FacebookHelper";
import RoutesHelper, { ROUTES } from "../../src/utils/consts";
import { useTypedSelector } from "../../src/utils/use-typed-selector";

export interface LoginInfo {
    email: string;
    first_name: string;
    last_name: string;
    password1: string;
    password2: string;
    formType: FormType;
}

enum FormType {
    Login = 'Login',
    Register = 'Register',
    ForgotPassword = 'ForgotPassword'
}

const loginInfoDefaultValues: LoginInfo = {
    email: '',
    first_name: '',
    last_name: '',
    password1: '',
    password2: '',
    formType: FormType.Login
}

const createFormikInitialValues = (loginInfo: LoginInfo) => {
    return {
        ...loginInfo,
        submit: null
    }
}

export enum LOGIN_REFERRER_ORIGIN {
    ORDER_CART = 'orderCart', 
    ORDER_SHIPPING = 'orderShipping',
    ORDER_VALIDATION = 'orderValidation',
    ORDER_INFORMATION = 'orderInformation',
    ORDER_RECAP = 'orderRecap',
    PAYMENT = 'orderPayment',
    ACCOUNT_SETTINGS = 'accountSettings',
    ACCOUNT_ORDERS = 'accountOrders',
    ACCOUNT_ORDER_DETAIL = 'accountOrderDetail',
    ACCOUNT_ORDER_MODIFICATION_CART = 'accountOrderModificationCart',
    ACCOUNT_ORDER_MODIFICATION_SHIPPING = 'accountOrderModificationShipping',
    ACCOUNT_ORDER_MODIFICATION_INFORMATION = 'accountOrderModificationInformation',
    ACCOUNT_ORDER_MODIFICATION_VALIDATION = 'accountOrderModificationValidation',
    ACCOUNT_ORDER_MODIFICATION_CONFIRMATION = 'accountOrderModificationConfirmation',
    ACCOUNT_BANKROLL_LIST = 'accountBankrollList',
    ACCOUNT_BANKROLL_ORDER_DETAIL = 'accountBankrollOrderDetail',
    ACCOUNT_CARTS = 'accountCarts'
}

export default function Index() {

    const router = useRouter()
    const dispatch = useDispatch()
    const cartApi = new CartApi()
    const meApi = new MeApi()
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showConfirmedPassword, setShowConfirmedPassword] = useState<boolean>(false);
    const [referrer, setReferrer] = useState<string | null>(null);
    const [cartId, setCartId] = useState<string|null>(null) // When coming from the order shipping
    const [orderId, setOrderId] = useState<string|null>(null) // When coming from payment, confirmation or account order detail
    const [orderTransactionSecret, setOrderTransactionSecret] = useState<string|null>(null) // When coming from payment

    const [errorMessage, setErrorMessage] = useState<string|null>(null)
    const [showResetPasswordRequestSuccess, setShowResetPasswordRequestSuccess] = useState(false)

    const [isLoading, setIsLoading] = useState<boolean>(false)

    const currentEvent = useTypedSelector((state) => state.eventReducer.currentEvent)


    useEffect(() => {
        if(!router.isReady) return;
        AmplitudeHelper.trackLoginRegisterOpened()
 
 
        if(router.query.referrer){
            setReferrer(router.query.referrer as string)

            if(router.query.referrer === LOGIN_REFERRER_ORIGIN.ORDER_CART || router.query.referrer === LOGIN_REFERRER_ORIGIN.ORDER_SHIPPING || router.query.referrer === LOGIN_REFERRER_ORIGIN.ORDER_VALIDATION || router.query.referrer === LOGIN_REFERRER_ORIGIN.ORDER_INFORMATION){
                setCartId(router.query.cartId as string)
            }else if(router.query.referrer === LOGIN_REFERRER_ORIGIN.ORDER_RECAP || router.query.referrer === LOGIN_REFERRER_ORIGIN.PAYMENT){
                setOrderId(router.query.orderId as string)
                setOrderTransactionSecret(router.query.key as string)
            }else if(router.query.referrer === LOGIN_REFERRER_ORIGIN.ACCOUNT_ORDER_DETAIL || router.query.referrer === LOGIN_REFERRER_ORIGIN.ACCOUNT_ORDER_MODIFICATION_CART || router.query.referrer === LOGIN_REFERRER_ORIGIN.ACCOUNT_ORDER_MODIFICATION_SHIPPING || router.query.referrer === LOGIN_REFERRER_ORIGIN.ACCOUNT_ORDER_MODIFICATION_INFORMATION || router.query.referrer === LOGIN_REFERRER_ORIGIN.ACCOUNT_ORDER_MODIFICATION_VALIDATION || router.query.referrer === LOGIN_REFERRER_ORIGIN.ACCOUNT_ORDER_MODIFICATION_CONFIRMATION){
                setOrderId(router.query.orderId as string)
            }
        }
    }, [router.isReady, router.query]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: createFormikInitialValues(loginInfoDefaultValues),
        validationSchema: Yup.object().shape({
            email: Yup.string().email(t("LOGIN.InvalidEmail") as string).max(255).required(t("LOGIN.RequiredEmail") as string),
            first_name: Yup.mixed<FormType>().when('formType', {
                is: FormType.Register,
                then: Yup.string().max(100).required(t("LOGIN.RequiredFirstName") as string)
            }),
            last_name: Yup.mixed<FormType>().when('formType', {
                is: FormType.Register,
                then: Yup.string().max(100).required(t("LOGIN.RequiredLastName") as string)
            }),
            password1: Yup.mixed<FormType>().when('formType', {
                is: (type: FormType) => type === FormType.Register || type === FormType.Login,
                then: Yup.string().min(8, t("LOGIN.PasswordMin") as string).max(255).required(t("LOGIN.RequiredPassword") as string)
            }),
            password2: Yup.mixed<FormType>().when('formType', {
                is: FormType.Register,
                then: Yup.string().oneOf([Yup.ref('password1')], t("LOGIN.IdenticalPasswords") as string).required(t("LOGIN.RequiredPassword") as string)
            }),
        }),
        onSubmit: async (values, helpers) => {
            try {
 
                helpers.setStatus({success: true});
                helpers.setSubmitting(false);
                let loginInfo: LoginInfo = {
                    email: values.email,
                    password1: values.password1,
                    password2: values.password2,
                    first_name: values.first_name,
                    last_name: values.last_name,
                    formType: values.formType
                }

                switch(values.formType) {
                    case FormType.Login:
                        KeratinSingleton.getInstance().login({username: loginInfo.email, password:loginInfo.password1},() => {
                            setCartToUser(() => {
                                handleUserLoggedIn()
                            })
                        },(error: KeratinErrors, keratinErrorMessage: string) => {
                            setIsLoading(false)
                            handleLoginError(error, keratinErrorMessage)
                        })
                        break;
                    case FormType.Register:
                        KeratinSingleton.getInstance().signup( {username: loginInfo.email, password: loginInfo.password1},() => {
                            KeratinSingleton.getInstance().login({username: loginInfo.email, password:loginInfo.password1},() => {
                                setCartToUser(() => {
                                    handleUserSignedIn(loginInfo)
                                })
                            },(error: KeratinErrors, keratinErrorMessage: string) => {
                                setIsLoading(false)
                                handleLoginError(error, keratinErrorMessage);
                            })

                            fbq.trackCompleteRegistration()
                        }, (error: KeratinErrors, keratinErrorMessage: string) => {
                            setIsLoading(false)
                            handleSignInError(error, keratinErrorMessage)
                        })
                        break;
                    case FormType.ForgotPassword:
                        KeratinSingleton.getInstance().requestPasswordReset(loginInfo.email, () => {
                            setShowResetPasswordRequestSuccess(true)
                            setIsLoading(false)
                        }, () => {
                            setErrorMessage(t("GLOBALS.RetryLater"))
                            setIsLoading(false)
                        })
                        break;
                }

            } catch (err) {
                console.error(err);
                helpers.setStatus({success: false});
                console.error(err)
                helpers.setSubmitting(false);
            }
        }
    });

    useEffect(() => {
        if(!formik.isValid) setIsLoading(false)
    }, [formik.errors, formik.isValid])


    function setCartToUser(onEnd: () => void){
        if(currentEvent){
            cartApi.setUser(currentEvent.cart.id).then(() => {
                onEnd()
            }).catch((error) => {
                BugsnagHelper.notify(new Error("Login-login#createRemoteCartIfUserLoggedIn: " + JSON.stringify(error)))
                onEnd()
            })
        }else{
            onEnd()
        }
    }

    async function handleUserLoggedIn() {
        try{
            const user = await meApi.getUser()
            dispatch(setUserLoggedIn(user))
            redirectAfterSuccessfulLogin()
        } catch (error) {
            BugsnagHelper.notify(new Error("login#login: " + JSON.stringify(error)))
            setErrorMessage(t("LOGIN.LoginError"))
            setIsLoading(false)
        }
    }

    function handleLoginError(error: KeratinErrors, keratinErrorMessage: string){
        switch (error) {
            case KeratinErrors.Missing: break;
            case KeratinErrors.Taken:break;
            case KeratinErrors.FormatInvalid:break;
            case KeratinErrors.Insecure:break;
            case KeratinErrors.Failed:
                setErrorMessage(t("GLOBALS.NetworkError"));
                break;
            case KeratinErrors.Locked:break;
            case KeratinErrors.Expired:break;
            case KeratinErrors.NotFound:break;
            case KeratinErrors.InvalidOrExpired:break;
            case KeratinErrors.Unknown:
                setErrorMessage(keratinErrorMessage);
                break;
        }
    }

    async function handleUserSignedIn(loginInfo: LoginInfo){
        try{
            await meApi.updateUser({
                email: loginInfo.email,
                firstname: loginInfo.first_name,
                lastname: loginInfo.last_name
            })
            const user = await meApi.getUser()
            dispatch(setUserLoggedIn(user))
            redirectAfterSuccessfulLogin()
        } catch (error) {
            BugsnagHelper.notify(new Error("login#register: " + JSON.stringify(error)))
            setErrorMessage(t("LOGIN.LoginError"))
            setIsLoading(false)
        }
    }

    function handleSignInError(error: KeratinErrors, keratinErrorMessage: string){
        switch (error) {
            case KeratinErrors.Missing: break;
            case KeratinErrors.Taken:
                setErrorMessage(t("LOGIN.UnavailableEmail"));
                break;
            case KeratinErrors.FormatInvalid:break;
            case KeratinErrors.Insecure:
                setErrorMessage(t("LOGIN.PwdNotSecure"));
                break;
            case KeratinErrors.Failed:break;
            case KeratinErrors.Locked:break;
            case KeratinErrors.Expired:break;
            case KeratinErrors.NotFound:break;
            case KeratinErrors.InvalidOrExpired:break;
            case KeratinErrors.Unknown:
                setErrorMessage(keratinErrorMessage);
                break;
        }
    }

    async function redirectAfterSuccessfulLogin(){
        if(referrer === LOGIN_REFERRER_ORIGIN.ORDER_CART){
            router.push({
                pathname: ROUTES.ORDERS.SHIPPING,
                query: {
                    cartId: cartId
                }
            })
            if(cartId) {
                cartApi.validateItems(cartId)
            }
        }else if(referrer === LOGIN_REFERRER_ORIGIN.ORDER_SHIPPING){
            router.push({
                // in the order shipping step, when we click on continue we will be asked to log in if we're not logged in,
                // so if we are redirected to the login page after clicking on "continuer", log in and redirect directly to the next step, information.
                pathname: ROUTES.ORDERS.INFORMATION,
                query: {
                    cartId: cartId
                }
            })
        }else if(referrer === LOGIN_REFERRER_ORIGIN.ORDER_INFORMATION){
            router.push({
                pathname: ROUTES.ORDERS.INFORMATION,
                query: {
                    cartId: cartId
                }
            })
        }else if (referrer === LOGIN_REFERRER_ORIGIN.ORDER_VALIDATION) {
            router.push({
                pathname: ROUTES.ORDERS.VALIDATION,
                query: {
                    cartId: cartId
                }
            })
        }else if (referrer === LOGIN_REFERRER_ORIGIN.ORDER_RECAP){
            router.push({
                pathname: ROUTES.ORDERS.RECAP,
                query: {
                    orderId: orderId,
                    transactionSecret: orderTransactionSecret
                }
            })
        }else if (referrer === LOGIN_REFERRER_ORIGIN.PAYMENT){
            router.push({
                pathname: ROUTES.ORDERS.PAYMENT,
                query: {
                    orderId: orderId,
                    transactionSecret: orderTransactionSecret
                }
            })
        }else if(referrer === LOGIN_REFERRER_ORIGIN.ACCOUNT_SETTINGS){
            router.push({pathname: ROUTES.ACCOUNT.SETTINGS})
        }else if(referrer === LOGIN_REFERRER_ORIGIN.ACCOUNT_ORDERS){
            router.push({pathname: ROUTES.ACCOUNT.ORDERS})
        }else if(referrer === LOGIN_REFERRER_ORIGIN.ACCOUNT_ORDER_DETAIL){
            router.push({pathname: RoutesHelper.getOrderRouteByID(orderId!!)})
        }else if(referrer === LOGIN_REFERRER_ORIGIN.ACCOUNT_ORDER_MODIFICATION_CART) {
            router.push({pathname: RoutesHelper.getOrderModificationCartRouteByID(orderId!!)})
        }else if(referrer === LOGIN_REFERRER_ORIGIN.ACCOUNT_ORDER_MODIFICATION_SHIPPING) {
            router.push({pathname: RoutesHelper.getOrderModificationShippingRouteByID(orderId!!)})
        }else if(referrer === LOGIN_REFERRER_ORIGIN.ACCOUNT_ORDER_MODIFICATION_INFORMATION) {
            router.push({pathname: RoutesHelper.getOrderModificationInformationRouteByID(orderId!!)})
        }else if(referrer === LOGIN_REFERRER_ORIGIN.ACCOUNT_ORDER_MODIFICATION_VALIDATION) {
            router.push({pathname: RoutesHelper.getOrderModificationValidationRouteByID(orderId!!)})
        }else if(referrer === LOGIN_REFERRER_ORIGIN.ACCOUNT_ORDER_MODIFICATION_CONFIRMATION) {
            router.push({pathname: RoutesHelper.getOrderModificationConfirmationRouteByID(orderId!!)})
        }else if(referrer === LOGIN_REFERRER_ORIGIN.ACCOUNT_BANKROLL_LIST) {
            router.push({pathname: ROUTES.ACCOUNT.BANKROLL})
        }else if(referrer === LOGIN_REFERRER_ORIGIN.ACCOUNT_CARTS) {
            router.push({pathname: ROUTES.ACCOUNT.CARTS})
        }else{
            router.push({pathname: '/'})
        }
    }

    const renderInputTitle = (formType: FormType) => {
        switch(formType) {
            case FormType.Login:
                return t("LOGIN.AlreadyClient");
            case FormType.Register:
                return t("LOGIN.NewClient");
            case FormType.ForgotPassword:
                return t("LOGIN.ForgotPassword");
        }
    }

    const getConfirmButtonLabel = (formType: FormType): string => {
        switch(formType) {
            case FormType.Login:
                return t("BUTTONS.Login");
            case FormType.Register:
                return t("BUTTONS.Signup");
            case FormType.ForgotPassword:
                return t("BUTTONS.Validate");
        }
    }

    const getSignInUpSwitchButtonLabel = (formType: FormType): string => {
        switch(formType) {
            case FormType.Login:
                return t("BUTTONS.CreateAccount");
            case FormType.Register:
                return t("BUTTONS.Login");
            default:
                throw "getSignInUpSwitchButtonLabel switch error. should not happen for: "+formType
        }
    }

    const handleSignInUpSwitchClick = () => {
        if(formik.values.formType === FormType.Login){
            formik.setFieldValue('formType', FormType.Register, true)
        }else{
            formik.setFieldValue('formType', FormType.Login, true)
        }

        // Reset the error message if there was any
        setErrorMessage(null)
    }

    const renderSignInUpSwitchTitle = (formType: FormType) => {
        switch(formType) {
            case FormType.Login:
                return t("LOGIN.NewClient");
            case FormType.Register:
                return t("LOGIN.AlreadyClient");
            default:
                throw 'renderSignInUpSwitchTitle switch error. should not happen for: '+formType
        }
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    };

    const handleClickShowConfirmedPassword = () => {
        setShowConfirmedPassword(!showConfirmedPassword)
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return (
        <>
            <Head>
                <title>{t("LOGIN.TabName")}</title>
            </Head>
            <form onSubmit={formik.handleSubmit}>

                <Grid container justifyContent={'center'}>
                    <Grid item sx={{m: 3}} xl={3} lg={4} md={6} xs={12}>
                        <Grid container direction={"column"}>

                            <Card sx={{mt: 3, p: 3}} elevation={1}>
                                <Grid container justifyContent={"center"}>

                                    <Grid container justifyContent={"center"}>
                                        <Link href="/">
                                            <Button>
                                                <img src="/assets/sedeo-logo.svg" title="sedeo-logo" alt="Home" width={250} height={95}/>
                                            </Button>
                                        </Link>
                                    </Grid>

                                    {
                                        !showResetPasswordRequestSuccess && <Grid item xs={12} sx={{pb: 3, pt: 1}}>
                                            <Typography variant={"h5"} sx={{color: 'text.primary', fontWeight:'bold', fontSize: '24px'}}>
                                                {renderInputTitle(formik.values.formType)}
                                            </Typography>
                                            {
                                                formik.values.formType === FormType.ForgotPassword ?
                                                    <Typography paragraph sx={{mt: 1, mb: 0, fontSize: '13px'}}>
                                                        {t("LOGIN.ResetPasswordMessage")}
                                                    </Typography>
                                                    : null
                                            }
                                        </Grid>
                                    }

                                    {
                                        showResetPasswordRequestSuccess ?
                                            <>
                                                <Typography textAlign={"center"} sx={{mt: 3, width: '100%', fontSize: '17px', fontWeight:'bold', }}>
                                                    {t("LOGIN.WeSentEmail")}
                                                </Typography>
                                                <Typography textAlign={"center"} sx={{mt: 1, fontSize: '16px', width: '100%',  fontWeight:'bold', }}>
                                                    {formik.values.email}
                                                </Typography>
                                                <Typography textAlign={"start"} sx={{mt: 3, mb: 0, width: '100%',  fontSize: '13px'}}>
                                                    {t("LOGIN.LinkToReset")}
                                                </Typography>
                                                <Typography textAlign={"start"} sx={{mt: 1, mb: 0, width: '100%',  fontSize: '13px'}}>
                                                    {t("LOGIN.EmailNotReceived")}
                                                </Typography>
                                                <Typography textAlign={"start"} sx={{mt: 1, mb: 0, width: '100%',  fontSize: '13px'}}>
                                                    {t("LOGIN.VerifyMail")}
                                                </Typography>
                                            </>
                                            :
                                            <Grid item xs={12} sx={{px: 1}}>
                                                <InputField
                                                    error={Boolean(formik.touched.email && formik.errors.email)}
                                                    fullWidth
                                                    inputPaddingY={1.5}
                                                    helperText={formik.touched.email && formik.errors.email}
                                                    label={t("GLOBALS.EmailAddress")}
                                                    labelFontSize={16}
                                                    isLabelBold={true}
                                                    name="email"
                                                    placeholder={"example@mail.fr"}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <MailOutlineIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    value={formik.values.email}
                                                />
                                            </Grid>
                                    }


                                    {
                                        formik.values.formType === FormType.Register ?
                                            <>
                                                <Grid item xs={12} sx={{px: 1, mt: 3}}>
                                                    <InputField
                                                        error={Boolean(formik.touched.first_name && formik.errors.first_name)}
                                                        fullWidth
                                                        inputPaddingY={1.5}
                                                        helperText={formik.touched.first_name && formik.errors.first_name}
                                                        label={t("GLOBALS.FirstName")}
                                                        labelFontSize={16}
                                                        isLabelBold={true}
                                                        name="first_name"
                                                        placeholder="Camille"
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <PersonOutlineIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                        value={formik.values.first_name}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sx={{px: 1, mt: 3}}>
                                                    <InputField
                                                        error={Boolean(formik.touched.last_name && formik.errors.last_name)}
                                                        fullWidth
                                                        inputPaddingY={1.5}
                                                        helperText={formik.touched.last_name && formik.errors.last_name}
                                                        label={t("GLOBALS.LastName")}
                                                        labelFontSize={16}
                                                        isLabelBold={true}
                                                        name="last_name"
                                                        placeholder={"Dupont"}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <PersonOutlineIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                        value={formik.values.last_name}
                                                    />
                                                </Grid>
                                            </>
                                            :null
                                    }

                                    {
                                        formik.values.formType !== FormType.ForgotPassword ?
                                            <>
                                                <Grid item xs={12} sx={{px: 1, mt: 3}}>
                                                    <InputField
                                                        error={Boolean(formik.touched.password1 && formik.errors.password1)}
                                                        fullWidth
                                                        inputPaddingY={1.5}
                                                        type={showPassword ? 'text' : 'password'}
                                                        placeholder={"********"}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <LockOutlinedIcon />
                                                                </InputAdornment>
                                                            ),
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={handleClickShowPassword}
                                                                    onMouseDown={handleMouseDownPassword}
                                                                    >
                                                                         {showPassword ? <VisibilityOff /> : <Visibility />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                        helperText={formik.touched.password1 && formik.errors.password1}
                                                        label={t("GLOBALS.Password")}
                                                        labelFontSize={16}
                                                        isLabelBold={true}
                                                        name="password1"
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                        value={formik.values.password1}
                                                    />
                                                </Grid>
                                                {
                                                    formik.values.formType === FormType.Register ?
                                                        <Grid item xs={12} sx={{px: 1, mt: 3}}>
                                                            <InputField
                                                                error={Boolean(formik.touched.password2 && formik.errors.password2)}
                                                                fullWidth
                                                                inputPaddingY={1.5}
                                                                type={showConfirmedPassword ? 'text' : 'password'}
                                                                placeholder={"********"}
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            <LockOutlinedIcon />
                                                                        </InputAdornment>
                                                                    ),
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            <IconButton
                                                                                aria-label="toggle password visibility"
                                                                                onClick={handleClickShowConfirmedPassword}
                                                                                onMouseDown={handleMouseDownPassword}
                                                                            >
                                                                                {showConfirmedPassword ? <VisibilityOff /> : <Visibility />}
                                                                            </IconButton>
                                                                        </InputAdornment>
                                                                    )
                                                                }}
                                                                helperText={formik.touched.password2 && formik.errors.password2}
                                                                label={t("GLOBALS.ConfirmPassword")}
                                                                labelFontSize={16}
                                                                isLabelBold={true}
                                                                name="password2"
                                                                onBlur={formik.handleBlur}
                                                                onChange={formik.handleChange}
                                                                value={formik.values.password2}
                                                            />
                                                        </Grid>
                                                        :

                                                        <Grid container justifyContent={"end"}>
                                                                <Button
                                                                    variant="text" onClick={() => formik.setFieldValue('formType', FormType.ForgotPassword, true)}
                                                                    sx={{mt: 0.5, color: SEDEO_PALETTE.lightBlue, fontSize: '12px', height: '30px', textDecoration:'none'}}>
                                                                    {t("LOGIN.ForgotPassword")}
                                                                </Button>
                                                        </Grid>
                                                }
                                            </>
                                        : null
                                    }

                                    <Grid item xs={12} sx={{ mt: 1, px: 1}}>
                                        <Typography paragraph sx={{ color: 'red'}}>
                                            {errorMessage}
                                        </Typography>
                                    </Grid>

                                    {
                                        !showResetPasswordRequestSuccess && <DefaultRoundButton
                                            type={"submit"}
                                            sx={{mt: 2, px: 5, py: 1}}
                                            disabled={isLoading || !formik.isValid}
                                            onClick={() => {
                                                setIsLoading(true)
                                                formik.submitForm()
                                            }}
                                        >
                                            {isLoading ? <LoadingAnimation/> : getConfirmButtonLabel(formik.values.formType)}
                                        </DefaultRoundButton>
                                    }


                                    {
                                        formik.values.formType === FormType.ForgotPassword ?

                                            <Grid container justifyContent={"center"}>
                                                <Button
                                                    variant="text"
                                                    onClick={() => {
                                                        setShowResetPasswordRequestSuccess(false)
                                                        formik.setFieldValue('formType', FormType.Login, true)
                                                    }}
                                                    sx={{mt: 1, color: SEDEO_PALETTE.lightBlue, height: '40px', textDecoration:'none'}}
                                                >
                                                    {t("LOGIN.ReturnToLogin")}
                                                </Button>
                                            </Grid>
                                            :null
                                    }
                                </Grid>
                            </Card>

                        </Grid>


                        {
                            formik.values.formType !== FormType.ForgotPassword?
                                <Card sx={{mt: 3, p: 3}} elevation={1}>
                                    <Grid container justifyContent={"center"}>
                                        <Grid item xs={12} sx={{pb: 3}}>
                                            <Typography variant={"h6"} sx={{color: 'text.primary', fontWeight:'bold', fontSize: '24px'}}>
                                                {renderSignInUpSwitchTitle(formik.values.formType)}
                                            </Typography>
                                        </Grid>
                                        <DefaultRoundButton
                                            sx={{mt: 0.5, px: 5, py: 1}}
                                            onClick={handleSignInUpSwitchClick}
                                        >
                                            {getSignInUpSwitchButtonLabel(formik.values.formType)}
                                        </DefaultRoundButton>
                                    </Grid>
                                </Card>

                                :null
                        }

                    </Grid>
                </Grid>
            </form>
        </>
    );
}
